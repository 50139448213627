import React, { Component, PureComponent } from 'react'
import VideoPlayer from 'react-video-js-player';
import axios from 'axios';
import $ from 'jquery';



var increaseView;
class VideoPlayerInRecordingPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            player: null,
            isIncreaseView: false,
            isIncreaseSimpleView: false,
            i: 0,
            isUpdate: true
        }
        this.countTime = this.countTime.bind(this);
    }

    // shouldComponentUpdate() {
    //     // if (this.props.recording != null) {
    //     //     return this.props.recording._id != nextProps.recording._id
    //     // }

    //     // return true;

    //     return this.state.isUpdate;
    // }

    componentWillUnmount() {
        clearInterval(increaseView)
    }

    onPlayerReady(player) {
        this.setState({
            player: player
        });

        var video = document.getElementsByTagName("video");
        video.item(0).setAttribute("playsinline", true);
        // video.item(0).setAttribute("controls", true);
        video.item(0).setAttribute("disablePictureInPicture", true);
    }

    onPlay(duration) {
        const { player } = this.state;
        const { user } = this.context;
        const { recording } = this.props;

        if (user == null) return;

        let json = {
            userId: user.facebookId,
            recordingId: recording._id,
            domain: window.location.hostname
        };

        if (!this.state.isIncreaseView) {
            clearInterval(increaseView);
            increaseView = setInterval(this.countTime, 1000, player.duration() * 0.7, json);
        }

        if (!this.state.isIncreaseSimpleView) {
            this.state.isIncreaseSimpleView = true;
            axios.post(global.config.apiDomain + "/rest/increaseSimpleViewCounter", JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {

            }, err => {
                console.log(err);
            });
        }

    }

    onPause(duration) {
        clearInterval(increaseView);
    }

    componentWillReceiveProps(props) {
        clearInterval(increaseView)
        if (this.state.player) {
            const { player } = this.state;
            player.src(props.recording.vocalUrl);
            player.poster(props.recording.thumbnailImageUrl);

            this.setState({
                isIncreaseView: false,
                i: 0
            })
        }

    }


    countTime(time, json) {
        this.state.i++;
        if (this.state.i >= time) {
            clearInterval(increaseView);
            this.state.isIncreaseView = true;

            axios.post(global.config.apiDomain + "/rest/increaseViewCounter", JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                // this.props.resetViewCounter();
            }, err => {
                console.log(err);
            })
        }
    }

    render() {
        const { recording } = this.props;

        let hideControls = [];
        if ($(window).width() < 576) {
            hideControls = ["volume"]
        }


        if (recording == null)
            return <></>

        this.state.isUpdate = false;
        let type = "";
        
        if (recording.vocalUrl.indexOf("?")>-1){
            recording.vocalUrl = recording.vocalUrl.substring(0,recording.vocalUrl.indexOf("?"));
              }  
        if (recording.vocalUrl.length > 3)
        type = recording.vocalUrl.substring(recording.vocalUrl.length - 3, recording.vocalUrl.length);
       //console.log(type + " URL: " + recording.vocalUrl);
        if (type == "mp3" ||  type == "mp4")
       {
        return (
            <VideoPlayer
                playsInline
                hideControls={hideControls}
                id="video-player"
                autoplay={true}
                onReady={this.onPlayerReady.bind(this)}
                controls={true}
                src={recording.vocalUrl.replace("http://", "https://")}
                poster={recording.thumbnailImageUrl}
                className='vjs-16-9'
                onPlay={this.onPlay.bind(this)}
                onPause={this.onPause.bind(this)}
                type="audio/mp3"

            ></VideoPlayer>
        )
    } else {
        return (

            <video 
            playsInline 
            hideControls={hideControls}
            id="video-player"
            autoplay={true}
            onReady={this.onPlayerReady.bind(this)}
            controls={true}
            src={recording.vocalUrl.replace("http://", "https://")}
            poster={recording.thumbnailImageUrl}
            className='vjs-tech'
            onPlay={this.onPlay.bind(this)}
            onPause={this.onPause.bind(this)}
            type="audio/mp3">
  <source autoplay={true} src={recording.vocalUrl.replace("http://", "https://")} type="video/mp4" />
</video>

        )
    }
    }
}

export default VideoPlayerInRecordingPage;
