import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default class ManagerNewDevice extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            originalNewDevices: [],
            newDevices: [],
            selectedDate: '',
            selectedEndDate:'',
            isUserLoging: '',
            languageValue: '',
            paidUser:'',
            platformValue: '',
            selectedRow: null,
            isLoading: false,
            load : id,
        };

        this.getAllCustomerOfDevice = this.getAllCustomerOfDevice.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleListAccountClick = this.handleListAccountClick.bind(this);
    }

    componentDidMount() {
        const day = window.localStorage.getItem("date");
        const endday = window.localStorage.getItem("enddate");
        console.log(day + " ngày " + endday)
        if(this.state.load ==1){
            this.setState({ selectedDate: day, selectedEndDate:endday }, () => {
                this.getAllCustomerOfDevice();
            });
        }else{
        const today = new Date().toISOString().split('T')[0];
        this.setState({ selectedDate: today, selectedEndDate:today }, () => {
            this.getAllCustomerOfDevice();
        });
    }
    }

    getAllCustomerOfDevice() {
        this.setState({ isLoading: true });
        const { selectedDate,selectedEndDate } = this.state;
        if(selectedDate <= selectedEndDate){
        axios.post(global.config.apiDomain + '/rest/new-device-and-user/new-device',{ selectedDate:selectedDate,selectedEndDate:selectedEndDate}, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                originalNewDevices: models,
                newDevices: models,
                isLoading: false
             });
        })
        .catch(error => {
            console.error('Error fetching commissions:', error);
            this.setState({ isLoading: false });
        });}else{
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu !")
            this.setState({ isLoading: false });
        }
        
    }

    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value });
    }
    handleDateEndChange(event) {
        this.setState({ selectedEndDate: event.target.value });
    }

    handleSelectDate() {
        this.getAllCustomerOfDevice();
        window.localStorage.setItem("date",this.state.selectedDate)
        window.localStorage.setItem("enddate",this.state.selectedEndDate)
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleSearch() {
        const { isUserLoging, languageValue, platformValue, originalNewDevices ,paidUser} = this.state;
    
        const filteredDevices = originalNewDevices.filter(device => {
            const isUserLogingMatch = (isUserLoging === "loging" && device.uid && device.uid !== "") ||
                                      (isUserLoging === "isLoging" && (!device.uid || device.uid === "")) ||
                                      (!isUserLoging || isUserLoging === "");
            const languageMatch = !languageValue || (device.language && device.language.toLowerCase().includes(languageValue.toLowerCase()));
            const platformMatch = !platformValue || (device.platform && device.platform.toLowerCase().includes(platformValue.toLowerCase()));
            var stringVariable = device.paidUser ? 'true' : 'false';
            const paidUserMatch = !paidUser || (stringVariable == paidUser);
            return isUserLogingMatch && languageMatch && platformMatch && paidUserMatch;
        });
        this.setState({
            newDevices: filteredDevices
        });
    }
    
    handleListAccountClick(index) {
        this.setState(prevState => ({
            selectedRow: prevState.selectedRow === index ? null : index
        }));
    }
    formatDate(dateString) {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
      
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
        return `${month}/${day}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
      }

    render() {
        const { newDevices, isUserLoging, languageValue, platformValue, isLoading ,paidUser } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">New Devices</h1>
                        <div className="search-container">
                            <input type="date" onChange={this.handleDateChange}></input>
                            Đến ngày 
                            <input type="date" onChange={this.handleDateEndChange}></input>
                            <button style={{minWidth: '90px'}} onClick={this.handleSelectDate}>Chọn ngày</button>

                            <select style={{marginLeft: '220px'}} name="isUserLoging" value={isUserLoging} onChange={this.handleInputChange}>
                                <option value="">Check Device</option>
                                <option value="loging">Có tài khoản đang đăng nhập</option>
                                <option value="isLoging">Không có tài khoản nào đang đăng nhập</option>
                            </select>
                            <select name="languageValue" value={languageValue} onChange={this.handleInputChange}>
                                <option value="">App</option>
                                <option value="vi">Ikara</option>
                                <option value="en.yokara">Yokara</option>
                            </select>
                            <select name="platformValue" value={platformValue} onChange={this.handleInputChange}>
                                <option value="">Platform</option>
                                <option value="IOS">IOS</option>
                                <option value="ANDROID">ANDROID</option>
                            </select>
                            <select name="paidUser" value={paidUser} onChange={this.handleInputChange}>
                                <option value="true">PaidUser</option>
                                <option value="true">Đã chi tiêu</option>
                                <option value="false">Chưa chi tiêu</option>
                            </select>
                            <button style={{minWidth: '80px'}} onClick={this.handleSearch}>Tìm kiếm</button>
                            <div style={{minWidth: '80px'}} className="quantity">Số lượng: {newDevices.length}</div>
                        </div>
                        {isLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : newDevices.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">AddTime</th>
                                        <th className="align-middle" scope="col">DeviceId</th>
                                        <th className="align-middle" scope="col">App</th>
                                        <th className="align-middle" scope="col">Hệ điều hành</th>
                                        <th className="align-middle" scope="col">Uid account</th>
                                        <th className="align-middle" scope="col">Name account</th>
                                        <th className="align-middle" scope="col">PaidUser</th>
                                        <th className="align-middle" scope="col">LastLogin</th>
                                        <th className="align-middle" scope="col">Action</th>
                                        {/* <th className="align-middle" scope="col">Danh sách Account đã đăng nhập</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {newDevices.map((item) => {
                                        let addTime = new Date(item.addTime);
                                        console.log(item.paidUser);
                                        return (
                                            <tr key={item.deviceId}>
                                                <td className="align-middle text-center" scope="col">{addTime.toLocaleString()}</td>
                                                {/* <td className="align-middle text-center" scope="col">{item.model}</td> */}
                                                <td className="align-middle text-center" scope="col">{item.deviceId}</td>
                                                <td className="align-middle text-center" scope="col">{item.language}</td>
                                                <td className="align-middle text-center" scope="col">{item.platform}</td>
                                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                {item.paidUser ==false ?
                                                <td className="align-middle text-center" scope="col">false</td> :<td className="align-middle text-center" scope="col">true</td>}
                                                {item.uid && item.uid !='' ?
                                                            <td scope="col" className="align-middle text-center">{this.formatDate(item.lastLogin)}</td>
                                                :
                                                <td scope="col" className="align-middle text-center">Chưa đăng nhập</td>}    
                                                <td className="align-middle text-center" scope="col">
                                                    {item.uid && item.uid !='' ?
                                                    <Link to={`/admin/support-new/${item.uid}`}>
                                                        <button type="button" class="btn btn-success">Nhắn Tin</button>
                                                        </Link> : <Link to={`/admin/support-new/${item.deviceId}`}>
                                                        <button type="button" class="btn btn-success">Nhắn Tin</button>
                                                        </Link>}</td>
                                                {/* <td className="align-middle text-center">
                                                    <div className="dropdown">
                                                        <button 
                                                            className="btn btn-link dropdown-toggle"
                                                            onClick={() => this.handleListAccountClick(item)}
                                                        >
                                                            Xem danh sách
                                                        </button>
                                                        <div className={`dropdown-menu ${this.state.selectedRow === item ? 'show' : ''}`}>
                                                            {this.state.selectedRow === item && (
                                                                <ul className="dropdown-list">
                                                                    {item.listAccountLoggerDevice.length > 0 ? (
                                                                        item.listAccountLoggerDevice.map((account, accIndex) => (
                                                                            <li key={accIndex}>
                                                                                Uid: {account.uid} - Name: {account.name}
                                                                                <br />
                                                                                Id: {account.id}
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        <li>Không có tài khoản</li>
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có thiết bị nào.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
