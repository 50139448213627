import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import Select from 'react-select';

export default class ModalUpdateDailyTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dailyTask: {
                screenId: '' // Giá trị mặc định
            },
            previewImage: null,
            loading: false,
            screens: []
        }
        this.saveDailyTask = this.saveDailyTask.bind(this);
        this.getAllScreens = this.getAllScreens.bind(this);
        this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    }

    componentDidMount() {
        this.getAllScreens();
    }    

    componentWillReceiveProps(props) {
        let { dailyTask } = props;
        if (dailyTask != null) {
            if (dailyTask.id == "") {
                this.setState({
                    dailyTask: dailyTask,
                    register: true
                });
            } else {
                this.setState({
                    dailyTask: {
                        ...dailyTask,
                        group: dailyTask.group || "",
                        type: dailyTask.type || "",
                        rank: dailyTask.rank || "",
                    },
                    previewImage: null,
                    register: false
                });
                if (dailyTask.image != null && dailyTask.image.endsWith("svga")) {
                    $("#show-image").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                    this.loadSVGA1(dailyTask.image);
                } else if (dailyTask.image != null) {
                    $("#show-image").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + dailyTask.image + "\"  />");
                }
            }
        }
    }

    getAllScreens() {
        this.setState({
            loading: true
        });
        axios.post(global.config.apiDomain + '/rest/screens/getAllScreen')
            .then(res => {
                this.setState({
                    screens: res.data.screenModels,
                    loading: false
                });
            })
            .catch(error => {
                console.error('Lỗi khi lấy danh sách màn hình:', error);
                this.setState({ loading: false });
            });
    }

    saveDailyTask() {
        const { dailyTask, previewImage, register } = this.state;
        if (previewImage) {
            dailyTask.image = previewImage;
        }

        this.setState({
            loading: true
        });
        if (register) {
            axios.post(global.config.apiDomain + '/rest/daily-task/create-daily-task', JSON.stringify(dailyTask), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateDailyTask").modal("hide");
                    this.props.resetList(res.data.data.dailyTask);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error occurred:", error);
                toast.error("Lỗi server, xử lý lại nha");
            });
        } else {
            axios.post(global.config.apiDomain + '/rest/daily-task/create-daily-task', JSON.stringify(dailyTask), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateDailyTask").modal("hide");
                    this.props.resetList(res.data.data.dailyTask);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error occurred:", error);
                toast.error("Lỗi server, xử lý lại nha");
            });
        }
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadImageToS3(event) {
        var target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }
        this.setState({
            loading: true
        });
        var bucketName = "ikara-data/avatar";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";
        var fileReader = new FileReader();
        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });
                    const previewImage = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        previewImage: previewImage
                    });
                    if (previewImage != null && (previewImage.endsWith(".svga") || previewImage.endsWith(".svg"))) {
                        $("#show-image").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                        this.loadSVGA1(previewImage);
                    } else {
                        $("#show-image").children().replaceWith(`<img style=\"height: 300px !important\" src=${previewImage} />`);
                    }
                    target.value = null;
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    handleScreenChange = (selectedOption) => {
        this.setState(prevState => ({
            dailyTask: {
                ...prevState.dailyTask,
                screenId: selectedOption ? selectedOption.value : ''
            }
        }));
    };

    render() {
        const model = bindModel(this);
        const { dailyTask, register, screens } = this.state;

        const fixedOptions = [
            { value: "NewUser_Profile", label: "NewUser_Profile" },
            { value: "NewUser_Recording", label: "NewUser_Recording" },
            { value: "ActiveLiveRoom", label: "ActiveLiveRoom" },
            { value: "ActiveTalkRoom", label: "ActiveTalkRoom"}
        ];

        const options = [
            ...fixedOptions,
            ...screens.map(screen => ({
                value: screen.id,
                label: screen.id
            }))
        ];

        const selectedScreen = options.find(option => option.value === dailyTask.screenId);

        return (
            <div>
                <div className="modal fade" id="modalUpdateDailyTask" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Nhiệm vụ</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div className="modal-body">
                                    {!this.props.register && (
                                    <div id="title" className="row mb-2">
                                        <div className="col-3">
                                        <label>Id</label>
                                        </div>
                                        <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            {...model('dailyTask.id')}
                                        />
                                        </div>
                                    </div>
                                    )}
                                    <div id="title" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="dailyTask.name"
                                                placeholder="Nhập tên nhiệm vụ"
                                                {...model('dailyTask.name')} />
                                        </div>
                                    </div>

                                    <div id="image" className="row mb-2">
                                        <div className="col-3">
                                            <label>Icon</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile03"
                                                onChange={this.uploadImageToS3}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-image" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Nhập màn hình đi tới</label>
                                        </div>
                                        <div className="col-9">
                                            <Select
                                                className="mb-2"
                                                name="dailyTask.screenId"
                                                options={options}
                                                value={selectedScreen ? selectedScreen : null}
                                                onChange={this.handleScreenChange}
                                                placeholder="Chọn ID màn hình đi tới"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Icoin nhận được</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="dailyTask.bonus"
                                                placeholder="Icoin nhận được"
                                                {...model('dailyTask.bonus')} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Số lượng nhiệm vụ</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                 type="number"
                                                 name="dailyTask.requestNumber"
                                                placeholder="Số lượng nhiệm vụ"
                                                {...model('dailyTask.requestNumber')} />
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Id nhiệm vụ</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="dailyTask.group"
                                                {...model('dailyTask.group')} >
                                                    <option value="">Chọn ID nhiệm vụ</option>
                                                    <option value="Tặng quà phòng kara">Tặng quà phòng kara</option>
                                                    <option value="Tặng quà bài thu">Tặng quà bài thu</option>
                                                    <option value="Tặng quà combo">Tặng quà combo</option>
                                                    <option value="Tặng lì xì">Tặng lì xì</option>
                                                    <option value="Tặng quà cặp đôi">Tặng quà cặp đôi</option>
                                                    <option value="Thu âm đơn ca">Thu âm đơn ca</option>
                                                    <option value="Thu âm song ca">Thu âm song ca</option>
                                                    <option value="Thu âm đơn ca có hình">Thu âm đơn ca có hình</option>
                                                    <option value="Thu âm song ca có hình">Thu âm song ca có hình</option>
                                                    <option value="Thích bài thu">Thích bài thu</option>
                                                    <option value="Bình luận bài thu">Bình luận bài thu</option>
                                                    <option value="Chia sẻ bài thu">Chia sẻ bài thu</option>
                                                    <option value="Nhặt lì xì">Nhặt lì xì</option>
                                                    <option value="Vào phòng kara">Vào phòng kara</option>
                                                    <option value="Vào phòng talk">Vào phòng talk</option>
                                                    <option value="Vào phòng game">Vào phòng game</option>
                                                    <option value="Chơi game">Chơi game</option>
                                                    <option value="Nhắn tin bạn bè">Nhắn tin bạn bè</option>
                                                    <option value="Hoàn thành hồ sơ">Hoàn thành hồ sơ</option>
                                                    <option value="Tham gia sự kiện">Tham gia sự kiện</option>
                                                    <option value="Theo dõi bạn bè">Theo dõi bạn bè</option>
                                                    <option value="Xử lý AI">Xử lý AI</option>
                                                    <option value="Nghe bài thu">Nghe bài thu</option>
                                                    <option value="Được bạn bè tặng quà">Được bạn bè tặng quà</option>
                                                    <option value="Mua VIP">Mua VIP</option>
                                                    <option value="Nạp Icoin">Nạp Icoin</option>
                                                    <option value="Ghép cặp đôi">Ghép cặp đôi</option>
                                                    <option value="Song ca cùng thần tượng">Song ca cùng thần tượng</option>
                                                    <option value="Lên bài">Lên bài</option>
                                                    <option value="Lên ghế">Lên ghế</option>
                                                    <option value="Tạo beat chờ song ca">Tạo beat chờ song ca</option>
                                                    <option value="Ghé thăm bạn bè">Ghé thăm bạn bè</option>
                                                    <option value="Thu âm có sticker">Thu âm có sticker</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Type</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="dailyTask.type"
                                                {...model('dailyTask.type')} >
                                                <option value="">Type</option>
                                                <option value="NEW_USER">NEW_USER</option>
                                                <option value="NORMAL">NORMAL</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div id="link" className="row mb-2">
                                        <div className="col-3">
                                            <label>Độ khó</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="dailyTask.rank"
                                                {...model('dailyTask.rank')} >
                                                <option value="">Độ khó</option>
                                                <option value="EASY">EASY</option>
                                                <option value="NORMAL">NORMAL</option>
                                                <option value="HARD">HARD</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveDailyTask}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}