import React, {Component} from 'react';
import axios from 'axios';
import bindModel from '../../../../common/bindModel.js';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link } from "react-router-dom";

export default class DetailYoutubeMidiPage extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            song: null,
            loading: false
        }

        this.uploadFile = this.uploadFile.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        let youtubeId = this.props.match.params.youtubeId;
        if(youtubeId != null) {
            this.getSong(youtubeId);
        } else {
            this.setState({
                song : {}
            });
        }
    }
    
    getSong(youtubeId) {
        axios.post(global.config.apiDomain + '/rest/youtubemidi/detail/' + youtubeId).then((res) => {
            const song = res.data;
            this.setState({
                song: song
            });
          })
    }

    save() {
        this.setState({
            loading: true
        })

        const {song} = this.state;
        
        axios.post(global.config.apiDomain + '/rest/youtubemidi/save', JSON.stringify(song), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            const {status, message} = res.data;
        
            if(status == "OK") {
                toast.success(message);
                this.getSong(song.videoId)
            } else {
                toast.error(message);
            }
            this.setState({
                loading: false
            })
        }).catch(error => {
            toast.error(error);
            this.setState({
                loading: false
            })
        })
    }

    uploadFile(event) {
        var target = event.target;

        this.setState({
            loading: true
        });

        var id = target.id;
        var {song} = this.state;
        var bucketName = "ikara-data";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = "youtubesongs/midi/" + song.videoId + "." + extension;
        var contentType = "audio/midi";
        if (extension != "mid" && extension != "midi") {
            toast.warn("Vui lòng chọn file midi.")
            this.setState({
                loading: false
            });

            return;
        }

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    song.midiUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    document.getElementById("midiUrl").value = "";

                    this.setState({
                        song: song
                    });
                }.bind(this),error: function(jqXHR, exception) {
                    document.getElementById("midiUrl").value = "";
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }


    getStatus(status) {
        switch(status) {
            case 'NEW':
                return <span className='text-secondary'>chưa xử lí</span>
            case 'PROCESSED':
                return <span className='text-success'>đã xử lí</span>
            case 'PROCESSING':
                return <span className='text-danger'>đang xử lí</span>
            default:
                return <span className='text-secondary'>chưa xử lí</span>
        }
    }
    
    render() {
        const model = bindModel(this);
        const {song, loading} = this.state;

        if(song == null) return <div id="content"></div>;

        return (
            <div id="content">
                <div className='row'>
                    
                </div>
                <div className="d-flex justify-content-between mb-4">
                    <Link style={{ width: "100px" }} to="/admin/youtube-midi" className="btn btn-secondary my-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp; Trở về</Link>
                    <h2 className='text-center'>Thông tin beat ({this.getStatus(song.midiStatus)})</h2>
                    <div></div>
                </div>
                    <div className='row mb-4 justify-content-center'>
                        <div className="col-2 my-auto">
                            <label>Tên beat</label>
                        </div>
                        <div className="col-8 col-lg-6">
                            <input 
                                className="form-control" 
                                value={song.songName}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div id="link" className="row mb-4 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Beat mp3: </label>
                        </div>
                        {
                            song.songUrl != null && song.songUrl != "" &&
                            <div className="col-8 col-lg-6">
                                <audio height={150} src={song.songUrl} controls></audio>
                            </div>
                        }
                    </div>

                    <div id="link" className="row mb-3 justify-content-center">
                        <div className="col-2 my-auto">
                            <label className="m-0 my-auto">Midi File: </label>
                        </div>
                        <div className="col-8 col-lg-6">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="midiUrl"
                                onChange={this.uploadFile}
                            />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="midiUrl">Choose file midi</label>
                        </div>
                    </div>

                    {
                        song.midiUrl != null && song.midiUrl != "" &&
                            <div id="link" className="row mb-4 justify-content-center">
                                <div className="col-2"></div>
                                <div className="col-8 col-lg-6">
                                    <a href={song.midiUrl} controls>{song.midiUrl}</a>
                                </div>
                            </div>
                    }

                    <div className='text-center my-3'>
                        {loading ? 
                            (
                                <>
                                    <button 
                                        style={{width: "120px"}} 
                                        className="btn btn-primary mx-1" 
                                        type="button" 
                                        disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Save  <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                    
                                </>
                            ) :
                            (
                                <>
                                    <button 
                                        style={{width: "120px"}} 
                                        type="button" 
                                        className="btn btn-primary mx-1" 
                                        onClick={this.save}>
                                            Save  <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                </>
                            )
                        }
                    </div>

            </div>
        )
    }
}