import React, { Component } from 'react';
import Moment from 'react-moment';
import ReactPlayer from 'react-player'

export default class MessageDetailItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { messageDetail, user, targetUser } = this.props;

        // Log the type of messageDetail.message
        console.log("Type of messageDetail.message:", typeof messageDetail.message);

        console.log("messageDetail.message:", messageDetail.message);
        console.log("messageDetail.reply:", messageDetail.replyMessageId);

        // Convert string to array if it's a JSON-formatted array
        let messageArray = messageDetail.message;
        if (typeof messageArray === 'string' && (messageDetail.type === "image" || messageDetail.type === "video")) {
            try {
                messageArray = JSON.parse(messageArray);
                console.log("messageArray.messageArray:", messageArray);

            } catch (error) {
                console.error("Error parsing messageDetail.message:", error);
                // Handle the error as needed
            }
        }

        return (
            <>
                {messageDetail.userId === targetUser.fromUserId ? (
                    <div className="media w-50 mb-3">
                        <img src={targetUser.userProfile} alt="user" width={50} className="rounded-circle" />
                        <div className="media-body ml-3">
                            <div style={{ wordBreak: "break-all" }} className="bg-light rounded py-2 px-3 mb-2 my-message">
                                {messageDetail.type === "image" && Array.isArray(messageArray) ? (
                                    // Render images directly
                                    messageArray.map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            style={{ maxWidth: '200px', marginRight: '5px' }}
                                            src={imageUrl}
                                            alt={`img-${index}`}
                                        />
                                    ))
                                ) : messageDetail.type === "video" && Array.isArray(messageArray) ? (
                                    // Render each video from the array
                                    messageArray.map((videoUrl, index) => (
                                        <ReactPlayer key={index} url={videoUrl} controls={true} width='220px' height='125px' />
                                    ))
                                ) : (
                                    // Render text message if not an image or video
                                    <p className="text-small mb-0 text-muted">
                                        {messageDetail.originalMessage != null
                                            ? messageDetail.originalMessage
                                            : messageDetail.message}
                                    </p>
                                )}
                            </div>
                            <p className="small text-muted">
                                Gửi vào ngày <Moment withTitle format="MM/DD">{messageDetail.dateTime}</Moment>,
                                lúc <Moment withTitle format="hh:mm">{messageDetail.dateTime}</Moment>
                            </p>

                        </div>
                    </div>
                ) : (
                    <div className="media w-50 ml-auto mb-3">
                        <div className="media-body">
                            <div style={{ wordBreak: "break-all" }} className="bg-primary rounded py-2 px-3 mb-2 target-message">
                                {messageDetail.type === "image" && Array.isArray(messageArray) ? (
                                    messageArray.map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            style={{ maxWidth: '200px', marginRight: '5px' }}
                                            src={imageUrl}
                                            alt={`img-${index}`}
                                        />
                                    ))
                                ) : messageDetail.type === "video" && Array.isArray(messageArray) ? (
                                    // Render each video from the array
                                    messageArray.map((videoUrl, index) => (
                                        <ReactPlayer key={index} url={videoUrl} controls={true} width='220px' height='125px' />
                                    ))
                                ) : (
                                    <p className="text-small mb-0 text-white">
                                        {messageDetail.originalMessage != null
                                            ? messageDetail.originalMessage
                                            : messageDetail.message}
                                    </p>
                                )}
                            </div>
                            <p className="small text-muted">
                                Gửi vào ngày <Moment withTitle format="MM/DD">
                                    {messageDetail.dateTime}
                                </Moment>,
                                lúc <Moment withTitle format="hh:mm">
                                    {messageDetail.dateTime}
                                </Moment>
                            </p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
