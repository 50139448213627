import React, { Component } from 'react';
import axios from 'axios';
import { param } from 'jquery';
import { toast } from 'react-toastify';

const $ = window.$;

export default class ModalGiffts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: null
        }

        this.getAllUserGift = this.getAllUserGift.bind(this);
        this.addNewUserGift = this.addNewUserGift.bind(this);
    }

    getAllUserGift() {
        let account = this.props.account;
        if (account) {
            var json = {
                facebookId: account.id,
            }
    
            axios.post(global.config.apiDomain + '/rest/admin/getAllUserGift', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log("getAllUserGift: ");
                console.log(res.data);
                this.setState({
                    gifts: res.data.gifts
                });
            })
    
        }
    }

    componentDidUpdate() {
        console.log('ModalGiffts                componentDidUpdate                   ');
        const { gifts } = this.state;
        if (gifts == undefined || gifts == null) {
            this.getAllUserGift();
        }
    }

    addNewUserGift() {
        let ok = true;
        let giftId = document.getElementById('newUserGift').value.trim();
        if (!giftId) {
            toast.error("Nhập ID Quà");
            return
        }
        const { gifts } = this.state;
        gifts && gifts.forEach(item => {
            if (item.id == giftId) {
                toast.error("Quà này đã có trong túi User!");
                ok = false;
                return
            }
        })
        if (!ok) return

        axios.post(global.config.apiDomain + '/rest/gifts/getDetail', giftId, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == 'OK') {
                let gift = res.data.gift;
                gift.noItem = 0;
                let gifts = this.state.gifts;
                gifts.unshift(gift);

                this.setState({
                    gifts: gifts
                });
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {
        const { gifts } = this.state;
        const { account } = this.props;

        return (
            <div>
                <div className='mt-2 mb-2 row'>
                    <div className='col-2'>
                    </div>
                    <input
                        type="text"
                        className="form-control col-4"
                        placeholder="Nhập ID Quà MỚI"
                        id='newUserGift'
                    />
                    <div className='col-2'>
                        <button className='btn btn-success' onClick={this.addNewUserGift}>Thêm vào</button>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">ID Quà</th>
                            <th scope="col">Tên Quà</th>
                            <th scope="col">Giá Mua</th>
                            <th scope="col">Giá Bán</th>
                            <th scope="col">Image</th>
                            <th scope="col">Số lượng</th>
                            <th scope="col">Tùy chỉnh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gifts && gifts.length > 0 ? (
                                gifts.map((gift, index) => {
                                    let idKey = `${account.id}#${gift.id}`;
                                    return (
                                        <tr key={gift.id}>
                                            <td>{index + 1}</td>
                                            <td>{gift.id}</td>
                                            <td>{gift.name}</td>
                                            <td>{gift.buyPrice}</td>
                                            <td>{gift.sellPrice}</td>
                                            <td><img src={gift.url} width="75"></img></td>
                                            <td>
                                                <div id={`tdValue${gift.id}`}>{gift.noItem}</div>
                                                <input type="number" className="form-control" id={`tdInput${gift.id}`} hidden />
                                            </td>
                                            <td id={`tdUpdate${gift.id}`} hidden>
                                                <button className='btn btn-success ml-2 mr-2' name={index}
                                                    onClick={(e) => {
                                                        let index = e.target.name;

                                                        let value = document.getElementById(`tdInput${gift.id}`).value;
                                                        let json = {
                                                            userId: account.id,
                                                            giftId: gift.id,
                                                            value: value
                                                        }
                                                        let tdInput = document.getElementById(`tdInput${gift.id}`);
                                                        let tdUpdate = document.getElementById(`tdUpdate${gift.id}`);
                                                        let tdValue = document.getElementById(`tdValue${gift.id}`);
                                                        let tdEdit = document.getElementById(`tdEdit${gift.id}`);
                                                        if (value < 0) {
                                                            return toast.warning("Không thể nhập số âm !");
                                                        }

                                                        if (window.confirm("Bạn có chắc là muốn thay đổi ?")) {
                                                            axios.post(global.config.apiDomain + '/rest/gifts/updateUserGift', json)
                                                                .then(res => {
                                                                    tdUpdate.setAttribute("hidden", true);
                                                                    tdInput.setAttribute("hidden", true);
                                                                    tdValue.removeAttribute('hidden');
                                                                    tdEdit.removeAttribute('hidden');

                                                                    gift.noItem = value;
                                                                    gifts[index] = gift;
                                                                    this.setState({
                                                                        gifts: gifts
                                                                    })
                                                                    return toast.success(res.data.message);
                                                                }).catch(err => {
                                                                    toast.error(err.message);
                                                                })
                                                        }
                                                    }}
                                                >Sửa
                                                </button>
                                                <button className='btn btn-primary' onClick={() => {
                                                    let tdInput = document.getElementById(`tdInput${gift.id}`);
                                                    let tdUpdate = document.getElementById(`tdUpdate${gift.id}`);
                                                    let tdValue = document.getElementById(`tdValue${gift.id}`);
                                                    let tdEdit = document.getElementById(`tdEdit${gift.id}`);
                                                    tdUpdate.setAttribute("hidden", true);
                                                    tdInput.setAttribute("hidden", true);
                                                    tdValue.removeAttribute('hidden');
                                                    tdEdit.removeAttribute('hidden');
                                                }}>Hủy</button>
                                            </td>
                                            <td id={`tdEdit${gift.id}`}><button className='btn btn-info ml-2 mr-2'
                                                onClick={() => {
                                                    let tdInput = document.getElementById(`tdInput${gift.id}`);
                                                    let tdUpdate = document.getElementById(`tdUpdate${gift.id}`);
                                                    let tdValue = document.getElementById(`tdValue${gift.id}`);
                                                    let tdEdit = document.getElementById(`tdEdit${gift.id}`);
                                                    tdEdit.setAttribute("hidden", true);
                                                    tdValue.setAttribute("hidden", true);
                                                    tdInput.removeAttribute('hidden');
                                                    tdInput.value = gift.noItem;
                                                    tdUpdate.removeAttribute('hidden');
                                                }}>Sửa</button>
                                                <button className='btn btn-danger' name={index}
                                                    onClick={(e) => {
                                                        if (window.confirm("Bạn có chắc là muốn xóa ?")) {
                                                            let json = {
                                                                userId: account.id,
                                                                giftId: gift.id
                                                            }
                                                            axios.post(global.config.apiDomain + '/rest/gifts/deleteUserGift', json)
                                                                .then(res => {
                                                                    let tdInput = document.getElementById(`tdInput${gift.id}`);
                                                                    let tdUpdate = document.getElementById(`tdUpdate${gift.id}`);
                                                                    let tdValue = document.getElementById(`tdValue${gift.id}`);
                                                                    let tdEdit = document.getElementById(`tdEdit${gift.id}`);
                                                                    tdUpdate.setAttribute("hidden", true);
                                                                    tdInput.setAttribute("hidden", true);
                                                                    tdValue.removeAttribute('hidden');
                                                                    tdEdit.removeAttribute('hidden');

                                                                    gift.noItem = 0;
                                                                    gifts[index] = gift;
                                                                    this.setState({
                                                                        gifts: gifts
                                                                    })
                                                                    return toast.success(res.data.message);
                                                                }).catch(err => {
                                                                    toast.error(err.message);
                                                                })
                                                        }
                                                    }}
                                                >Xóa
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (<></>)
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}