import React, { Component } from 'react';
import axios from 'axios';
import './../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default class ManagerDeadAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            page: 0,
            statusButtonLoadPage: false,
            isLoading: false,
            isInitialLoading: true,
            years: '',
            error: null
        };

        this.getAccountList = this.getAccountList.bind(this);
        this.handleYearsChange = this.handleYearsChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.getAccountList();
    }

    getAccountList() {
        const { isInitialLoading } = this.state;

        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }
        axios.post(global.config.apiDomain + `/rest/admin/dead-account/${this.state.page}?year=${this.state.years}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            let recordings = res.data;
            let status = true;
            if (recordings.length < 1) {
                status = false;
            }

            this.setState((prevState) => ({
                recordings: prevState.recordings.concat(recordings),
                page: prevState.page + 1,
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false,
                error: error.response.data.message
            });
            console.error("Error:", error);
        });
    }

    handleYearsChange(event) {
        this.setState({ years: event.target.value, error: null });
    }

    handleSearch() {
        const { years } = this.state;
        if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(years)) {
            this.setState({ error: "Định dạng phải là MM/yyyy, ví dụ: 01/2023" });
            return;
        }
    
        this.setState({ page: 0, recordings: [], isInitialLoading: true, statusButtonLoadPage: false, error: null }, () => {
            this.getAccountList();
        });
    }
    

    render() {
        const { recordings, statusButtonLoadPage, isLoading, isInitialLoading, years, error } = this.state;

        return (
            <>
                <div id="content">   
                    <div className="container-fluid">
                    <div className="container-fluid">
                            <h1 className="text-center">Danh sách Account ngừng hoạt động</h1>
                        </div>
                    <div className="search-container">
                        <input style={{width: '190px'}}
                            type="text"
                            value={years}
                            onChange={this.handleYearsChange}
                            placeholder="Nhập tháng/năm (MM/yyyy)"
                            pattern="(0[1-9]|1[0-2])\/[0-9]{4}"
                            title="Định dạng phải là MM/yyyy, ví dụ: 01/2023"
                            required
                        />
                         <button onClick={this.handleSearch} className="btn btn-primary">Tìm kiếm</button>
                        <div style={{ minWidth: '80px', display: 'flex', justifyContent: 'end', marginRight: '20px', fontWeight: 'bold'}} className="quantity">Số lượng: {recordings.length}</div>
                    </div>   
                    {error && (
                        <div className="text-danger">{error}</div>
                    )}
                    {isInitialLoading ? (
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    ) : recordings.length > 0 ? (
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">Last access</th>
                                    <th className="align-middle" scope="col">Uid</th>
                                    <th className="align-middle" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {recordings.map((item) => {
                                    let lastAccess = new Date(item.lastAccess)
                                    return (
                                        <tr key={item.uid}>
                                            <td className="align-middle text-center" scope="col">{lastAccess.toLocaleString()}</td>
                                            <td className="align-middle text-center" scope="col">{item.uid}</td>
                                            <td className="align-middle text-center" scope="col">
                                                <button
                                                    className='btn btn-success ml-2 mr-2'
                                                    onClick={() => window.open(`/admin/list-recordings/${item.uid}`, '_blank')}
                                                >
                                                    Danh sách bài thu
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center">
                            <p>Không có Account nào.</p>
                        </div>
                    )}
                    {
                        statusButtonLoadPage ?
                            (<div onClick={this.getAccountList} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                </a>
                            </div>) :
                            ('')
                    }
                </div>
                </div>
            </>
        );
    }
}
