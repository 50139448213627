import React, { Component } from 'react';
import BotListPage from './Components/BotListPage.js';
import CommentListPage from './Components/CommentListPage.js';
import axios from 'axios';
import CommentPage from './Components/CommentPage.js';
import './css/style.css';
import MessagePage from './Components/MessagePage.js';

export default class BOTInteractManagerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            recording: null,
            giftList: [],
            loadRecording: false,
            isComponentUpdate: true,
            loadRecording: false,
            showCommentList: false,
            showMessageList: false,
            selectedBotParam: null,
            user: null,
            commentPage: true,
            messagePage: false
        };

        this.getRecording = this.getRecording.bind(this);
        this.handleBotClick = this.handleBotClick.bind(this);
        this.handleBotClickMessage = this.handleBotClickMessage.bind(this);
        this.changePageMode = this.changePageMode.bind(this);
    }

    handleBotClick(param) {
        this.setState({
            showCommentList: true,
            selectedBotParam: param,
            user: {
                "facebookId": param,
                "level": 2
            },
            recording: null
        });
    }

    handleBotClickMessage(param) {
        this.setState({
            showMessageList: true,
            selectedBotParam: param
        });
        console.log("Bot ", param);
    }

    getRecording(recordid) {
        var recordingId = recordid;
        const json = JSON.stringify({ domain: window.location.hostname, recordingId: recordingId });

        axios.post(global.config.apiDomain + '/rest/recordingpage', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const { error, recording, gifts } = res.data;
            this.setState(
                {
                    recording: recording,
                    giftList: gifts,
                    error: error,
                    loadRecording: true
                });
            console.log("record hiện tại:" + recordingId);
        })
    }

    refreshPage() {
        window.location.reload();
    }

    changePageMode(e) {
        e.preventDefault();
        e.stopPropagation();
        const buttonContent = e.target.innerText;
        if (buttonContent === "Comment") {
            this.setState({
                commentPage: true,
                messagePage: false
            });
        } else if (buttonContent === "Message") {
            this.setState({
                commentPage: false,
                messagePage: true
            });
        }
    }


    render() {
        const { showCommentList, showMessageList, selectedBotParam, commentPage, messagePage } = this.state;
        const { error, recording, giftList, user } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí tương tác BOT</h1>
                        <div className="refresh-container" onClick={this.refreshPage}>
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                            <h5 style={{ fontFamily: "Roboto" }}>Làm mới trang</h5>
                        </div>
                        <div className="button-container">
                            <button onClick={this.changePageMode}>Comment</button>
                            <button onClick={this.changePageMode}>Message</button>
                        </div>
                        {commentPage && (<table className="table table-hover comment-div">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col" style={{ width: '17%' }}>Danh sách BOT</th>
                                    <th className="align-middle" scope="col" style={{ width: '17%' }}>Danh sách bình luận</th>
                                    <th className="align-middle" scope="col" style={{ width: '50%' }}>Nội dung và phản hồi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="custom-column" style={{ paddingTop: "0px", borderTop: "0px" }}>
                                        <BotListPage onBotClick={this.handleBotClick}></BotListPage>
                                    </td>
                                    <td className="custom-column" style={{ paddingTop: "0px", borderTop: "0px" }}>
                                        {showCommentList && (
                                            <CommentListPage
                                                param={selectedBotParam}
                                                onCommentClick={this.getRecording}
                                            />
                                        )}
                                    </td>
                                    <td className=".custom-column-content" style={{ paddingTop: "0px", borderTop: "0px" }}>
                                        {recording ? (
                                            <CommentPage user={user} giftList={giftList} recording={recording} noCommentPerRequest={5}></CommentPage>
                                        ) : <></>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>)}
                        {messagePage && (<table className="table table-hover message-div">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col" style={{ width: '20%' }}>Danh sách BOT</th>
                                    <th className="align-middle" scope="col" style={{ width: '80%' }}>Danh sách tin nhắn</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="custom-column" style={{ paddingTop: "0px", borderTop: "0px" }}>
                                        <BotListPage mode={"messagePage"} onBotClick={this.handleBotClickMessage}></BotListPage>
                                    </td>
                                    <td className=".custom-column-content" style={{ paddingTop: "0px", borderTop: "0px" }}>
                                        {showMessageList && (
                                            <MessagePage
                                                param={selectedBotParam}
                                            />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>)}
                    </div>
                </div>


            </>
        );
    }
}
